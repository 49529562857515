<template>
  <div class="profile-header-nav">
    <!-- navbar -->
    <nav class="navbar navbar-expand-md navbar-light justify-content-end justify-content-md-between w-100">
      <!-- collapse  -->
      <div
        id="navbarSupportedContent"
        class="collapse navbar-collapse"
      >
        <div class="profile-tabs d-flex justify-content-between flex-wrap mt-1 mt-md-0">

          <ul class="nav nav-pills mb-0">
            <li
              v-if="$auth.isAuthenticated"
              class="nav-item"
            >
              <b-link
                class="nav-link"
                exact-active-class="active"
                :class="{'active': isMakeAppointmentActiveLink}"
                :to="{ name: routes.STYLIST_PAGE }"
              >
                Make an appointment
              </b-link>
            </li>

            <li
              v-if="$auth.isAuthenticated"
              class="nav-item"
            >
              <b-link
                class="nav-link"
                exact-active-class="active"
                :to="{ name: routes.BOOKINGS_LIST }"
              >
                My upcoming appointments {{ appointments !== false ? `(${appointments})` : '' }}
              </b-link>
            </li>
          </ul>

          <div v-if="$auth.isAuthenticated">
            <b-button
              variant="outline-secondary"
              :to="{ name: routes.AUTH_LOGOUT }"
            >
              Logout
            </b-button>
          </div>

          <div v-if="!$auth.isAuthenticated">
            <span class="login-request">To manage your upcoming appointments,&nbsp;</span>

            <b-button
              variant="primary"
              @click="signIn()"
            >
              Sign in
            </b-button>
          </div>
        </div>
      </div>
      <!--/ collapse  -->
    </nav>
    <!--/ navbar -->
  </div>
</template>

<script>
import {
  BButton, BLink,
} from 'bootstrap-vue'
import routes, { BOOKING_FIRST_STEP_PAGE, BOOKING_SECOND_STEP_PAGE, BOOKING_SUCCESS_PAGE } from '@/router/routes/routes-names'
import { mapActions, mapState } from 'vuex'

const BOOKING_PAGES_LIST = [
  BOOKING_FIRST_STEP_PAGE,
  BOOKING_SECOND_STEP_PAGE,
  BOOKING_SUCCESS_PAGE,
]

export default {
  components: {
    BButton, BLink,
  },
  data() {
    return {
      routes,
    }
  },
  computed: {
    ...mapState('BookingServiceStoreModule', {
      appointments: state => (state.appointments.response !== null)
        && state.appointments.response
          .filter(a => a.status !== 'cancelled')
          .length,
    }),
    isMakeAppointmentActiveLink() {
      return BOOKING_PAGES_LIST.indexOf(this.$route.name) >= 0
    },
  },
  created() {
    if (this.appointments === false && this.$auth.isAuthenticated) {
      this.fetchAppointments()
    }
  },
  methods: {
    ...mapActions('BookingServiceStoreModule', [
      'fetchAppointments',
    ]),
    signIn() {
      this.$authCustomer.loginWithRedirect()
    },
  },
}
</script>

<style lang="scss" scoped>
.profile-header-nav {
  min-height: 4rem !important;
}
</style>
