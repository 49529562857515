<template>
  <div class="profile-header mb-2">
    <div class="position-relative">
      <img
        :src="backgroundImage + '?no_cache=' + Date.now()"
        onerror="this.src='/default_background.jpg'"
        class="bg-img-style"
      >
      <!-- go to stylist dashboard -->
      <div
        v-if="isAuthenticated"
        class="go-to-dashboard"
      >
        <b-button
          :to="{ path: APPS_STYLIST_DEFAULT }"
        >
          Open my dashboard
        </b-button>
      </div>
      <!-- / go to stylist dashboard -->

      <div
        v-if="isAuthenticated"
        v-b-modal.modal-bg
        class="img-change-icon"
      >
        <camera-icon
          size="1.5x"
          class="custom-class"
        />
      </div>
    </div>

    <b-modal
      id="modal-bg"
      title="Upload background image"
      @ok="handleOk"
    >
      <p>Select image for upload:</p>
      <slim-cropper :options="slimOptionsBackground">
        <input
          type="file"
          name="slim"
        >
      </slim-cropper>
    </b-modal>

    <b-modal
      id="modal-pr"
      title="Upload profile image"
      @ok="handleOk"
    >
      <p>Select image for upload:</p>
      <slim-cropper :options="slimOptionsProfile">
        <input
          type="file"
          name="slim"
        >
      </slim-cropper>
    </b-modal>

    <!-- profile picture -->
    <div class="position-relative">
      <div class="profile-img-container d-flex align-items-center">
        <div
          class="profile-img position-relative"
        >
          <b-img
            :src="profileImage + '?no_cache=' + Date.now()"
            onerror="this.src='/default_profile.png'"
            rounded
            fluid
            alt="profile photo"
            class="profile-img-style"
          />
          <div
            v-if="isAuthenticated"
            v-b-modal.modal-pr
            class="img-change-icon"
          >
            <camera-icon
              size="1.5x"
              class="custom-class"
            />
          </div>
        </div>

        <!-- profile title -->
        <div class="profile-title ml-3">
          <h2 class="text-white">
            {{ headerData.businessName }}
          </h2>
        </div>
        <!--/ profile title -->
      </div>
    </div>
    <!--/ profile picture -->
    <stylist-page-navbar />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import {
  BImg,
  BModal,
  BButton,
} from 'bootstrap-vue'
import Slim from '@/libs/slim-cropper/slim.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  CameraIcon,
} from 'vue-feather-icons'
import { APPS_STYLIST_DEFAULT } from '@/router/routes/routes-path'
import StylistPageNavbar from './StylistPageNavbar.vue'

export default {
  components: {
    StylistPageNavbar,
    BImg,
    CameraIcon,
    BModal,
    BButton,
    'slim-cropper': Slim,
  },
  directives: {
    Ripple,
  },
  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      file: '',
      profileImage: `${process.env.VUE_APP_S3_IMAGES_PATH}/profile_${this.$store.getters['app/getSubdomain']}?no_cache=1`,
      backgroundImage: `${process.env.VUE_APP_S3_IMAGES_PATH}/background_${this.$store.getters['app/getSubdomain']}?no_cache=1`,
      slimOptionsProfile: {
        ratio: '1:1',
        service: this.updatePrImg,
      },
      slimOptionsBackground: {
        service: this.updateBgImg,
      },
      isAuthenticated: this.isAuthenticatedToChangePhoto(),
      APPS_STYLIST_DEFAULT,
    }
  },

  methods: {
    ...mapActions('StylistPageStoreModule', [
      'updateProfileImage',
      'updateBackgroundImage',
    ]),
    isAuthenticatedToChangePhoto() {
      const {
        isLogged, subdomain, roles,
      } = Vue.prototype.$auth
      let authenticated = false
      if (isLogged && roles.includes('ROLE_STYLIST') && subdomain === this.$store.getters['app/getSubdomain']) {
        authenticated = true
      }
      return authenticated
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      document.querySelector('.slim-btn-upload').click()
    },
    async updatePrImg(formData = new FormData()) {
      try {
        await this.updateProfileImage({ subdomain: this.$store.getters['app/getSubdomain'], formData })
        this.success()
        this.$bvModal.hide('modal-pr')
        this.profileImage = `${this.profileImage}2`
        this.file = ''
      } catch (e) {
        this.fail()
        this.$bvModal.hide('modal-pr')
        this.file = ''
      }
    },
    async updateBgImg(formData = new FormData()) {
      try {
        await this.updateBackgroundImage({ subdomain: this.$store.getters['app/getSubdomain'], formData })
        this.success()
        this.$bvModal.hide('modal-bg')
        this.backgroundImage = `${this.backgroundImage}2`
        this.file = ''
      } catch (e) {
        this.fail()
        this.$bvModal.hide('modal-bg')
        this.file = ''
      }
    },
    success() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Image changed succesfully',
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
    },
    fail() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error uploading image, try later.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.bg-img-style{
max-height: 200px;
width: 100%;
object-fit: cover;
}

.img-change-icon{
position: absolute;
bottom: 5px;
right: 5px;
background-color: white;
padding: 5px;
border-radius: 100%;
}
.profile-img-style{
width: 100%;
height: 100%;
object-fit: cover;
}
.position-relative{
position: relative !important;
}

.go-to-dashboard{
display: inline-block;
position: absolute;
top: 10px;
right: 10px;
}
</style>
